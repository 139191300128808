import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { GeneralDropdownProps } from "./types";

import { PAYLOAD_ID } from "../../types";

import UploadFile from "../../../../../Files/UploadFile/UploadFile";

import { useDialog } from "../../../../../../ActionBar/hooks";
import { FILE_ACTIONS } from "../../../../../../types";

import QueryDropdown from "../../../../../../../QueryDropdown";

import {
    COLUMN_RESOURCES,
    DROPDOWN_SIZES,
    ENDPOINTS,
    FILE_TYPES,
    ID_TYPE,
    Query,
    TASK_TYPES
} from "../../../../../../../../shared";

const GeneralDropdown = ({
    secondSelectName,
    isLoading,
    additionalData,
    taskType,
    additionalBanner,
    changePayload,
    changeAdditionalData
}: GeneralDropdownProps) => {
    const { t } = useTranslation();
    const { isOpenDialog, openDialog, closeDialog } = useDialog();

    const getTooltipTitle = useCallback(() => {
        const tooltipTitleMain = t(
            `Dialog##no dropdown files##${secondSelectName}##main`
        );

        const tooltipTitleDescription = t(
            `Dialog##no dropdown files##${secondSelectName}##description`
        );

        return `${tooltipTitleMain} ${tooltipTitleDescription}`;
    }, [secondSelectName, t]);

    const [tooltipTitle, setTooltipTitle] = useState(getTooltipTitle());
    const [hasUploadedFile, setUploadedFile] = useState(false);

    useEffect(() => {
        setTooltipTitle(getTooltipTitle());
    }, [getTooltipTitle]);

    const { value } = additionalData;

    const handleChange = (newValue: Query | null) => {
        const isUploadFirmwareTask = taskType === TASK_TYPES.TxFirmware;

        const payload =
            newValue !== null
                ? { id: PAYLOAD_ID.FileId, value: newValue.id }
                : null;

        changePayload(payload);

        changeAdditionalData({
            value: newValue,
            ...(isUploadFirmwareTask
                ? { fileAttributes: newValue?.attributes || null }
                : {})
        });
    };

    return (
        <>
            <QueryDropdown
                data-testid="second-select-dropdown"
                fullWidth
                isRequired
                noOptionsTooltip={{
                    title: tooltipTitle,
                    fileType: secondSelectName as FILE_TYPES,
                    hasUploadedFile,
                    buttonText: t("Button##upload file"),
                    onButtonClick: () => openDialog(FILE_ACTIONS.UploadFiles)
                }}
                value={value}
                idType={ID_TYPE.Id}
                size={DROPDOWN_SIZES.Medium}
                labelLeft={t(`General##${secondSelectName}`)}
                readOnly={isLoading}
                descriptionResource={[
                    COLUMN_RESOURCES.Company,
                    COLUMN_RESOURCES.Name
                ]}
                textFieldParams={{
                    placeholder: t("Dialog##select")
                }}
                resource={`${ENDPOINTS.Files}?type=${secondSelectName}`}
                change={(_, fileValue) => handleChange(fileValue)}
            />

            <UploadFile
                isOpen={isOpenDialog[FILE_ACTIONS.UploadFiles]}
                close={closeDialog}
                onEnter={() => setTooltipTitle("")}
                onExited={() => setTooltipTitle(getTooltipTitle())}
                successCallback={() => setUploadedFile(!hasUploadedFile)}
            />

            {additionalBanner}
        </>
    );
};

export default GeneralDropdown;
