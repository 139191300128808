/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import TableCell from "@mui/material/TableCell";

import { TableColumnProps } from "./types";

import { useTableFunctions } from "../../hooks";

import {
    TOOLTIP_PLACEMENT,
    TooltipGeneral
} from "../../../DesignComponents/Tooltips";

import ThemeContext from "../../../../context/theme/themeContext";
import TableContext from "../../../../context/table/tableContext";
import {
    parseResource,
    tableColumnRightDropShadow,
    useOverflow
} from "../../../../shared";

const TableColumn = ({
    data,
    name,
    resource,
    isRestricted,

    isSticky,
    isTranslatable,
    customDateTimeFormat,
    scrollHorizontal
}: TableColumnProps) => {
    const {
        colorsFacelift: { gray200, textDark, textDarkDisabled }
    } = useContext(ThemeContext);

    const { isNotSelectable, columns, rows } = useContext(TableContext);

    const { measuredRef, isOverflowing } = useOverflow([columns, rows]);
    const { renderValue } = useTableFunctions();

    const paddingLeft = () => {
        if (isSticky) {
            return isNotSelectable ? "0" : "60px";
        }

        return "unset";
    };

    const parsedValue = parseResource(resource, data);

    const value = renderValue(
        name,
        resource,
        data,
        customDateTimeFormat,
        isTranslatable
    );

    const textColor = isRestricted && parsedValue ? textDarkDisabled : textDark;
    const boxShadowBottom = `inset 0px -1px 0px ${gray200}`;

    return (
        <TooltipGeneral
            title={renderValue(
                name,
                resource,
                data,
                customDateTimeFormat,
                isTranslatable
            )}
            disableHoverListener={!isOverflowing}
            placement={TOOLTIP_PLACEMENT.Top}
        >
            <TableCell
                ref={measuredRef}
                css={css([
                    {
                        color: textColor,
                        position: isSticky ? "sticky" : "unset",
                        left: paddingLeft(),
                        boxShadow: boxShadowBottom
                    },
                    isSticky && tableColumnRightDropShadow(scrollHorizontal)
                ])}
            >
                {value}
            </TableCell>
        </TooltipGeneral>
    );
};

export default TableColumn;
