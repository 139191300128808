import { useEffect, useState } from "react";

import { SecondSelectData } from "../SecondSelectContent/types";
import { SchedulePayload, SelectedTask } from "../types";

import useTaskTypes from "../../hooks/useTaskTypes";
import { SCHEDULE_TYPE, Task } from "../../types";

import {
    FEATURE_SETTINGS,
    useCompanyLevelSettings
} from "../../../../../../../shared";

const useCreateTaskContent = (setPayload: (data: any) => void) => {
    const { isFeatureSettingsRestricted } = useCompanyLevelSettings();
    const { visibleTasks } = useTaskTypes();

    const [selectedTask, setSelectedTask] = useState<SelectedTask>(null);

    const [secondSelectData, setSecondSelectData] =
        useState<SecondSelectData | null>(null);

    const [isScheduled, setScheduled] = useState(false);
    const [isCancelTasksChecked, setIsCancelTasksChecked] = useState(true);
    const [scheduledTime, setScheduledTime] = useState<SchedulePayload | null>(
        null
    );

    useEffect(() => {
        if (
            selectedTask &&
            (selectedTask.secondSelect ? secondSelectData !== null : true)
        ) {
            const data = {
                type: selectedTask.type,
                secondSelectData,
                schedule:
                    isScheduled && scheduledTime !== null
                        ? {
                              type: SCHEDULE_TYPE.Type,
                              attributes: {
                                  from_minutes: scheduledTime.from,
                                  duration_minutes: scheduledTime.duration
                              }
                          }
                        : null,
                expire_existing_tasks: isCancelTasksChecked
            };

            setPayload(data);
        } else {
            setPayload(null);
        }
        // eslint-disable-next-line
    }, [
        selectedTask,
        secondSelectData,
        isScheduled,
        scheduledTime,
        isCancelTasksChecked
    ]);

    const handleChangeValue = (taskType: Task) => {
        setSelectedTask(taskType);
        setSecondSelectData(null);
    };

    const handleSecondSelectData = (data: SecondSelectData | null) =>
        setSecondSelectData(data);

    const toggleTaskCancellation = () =>
        setIsCancelTasksChecked(!isCancelTasksChecked);

    const toggleScheduled = () => setScheduled(!isScheduled);

    const handleScheduledTime = (data: SchedulePayload) =>
        setScheduledTime(data);

    const isRestricted = isFeatureSettingsRestricted(
        FEATURE_SETTINGS.TaskScheduleSupport
    );

    return {
        isCancelTasksChecked,
        isRestricted,
        isScheduled,
        selectedTask,
        visibleTasks,
        handleChangeValue,
        handleScheduledTime,
        handleSecondSelectData,
        toggleScheduled,
        toggleTaskCancellation
    };
};

export { useCreateTaskContent };
