import { useEffect, useState } from "react";

import {
    Feature,
    FEATURES,
    FeatureValue
} from "../SecondSelectContent/FeatureControl/types";
import { SelectedTask } from "../types";

const useFeatureControl = (selectedTask: SelectedTask) => {
    const features: Feature[] = [{ type: FEATURES.Obd, name: "obd oem" }];

    const [feature, setFeature] = useState<FeatureValue>(null);

    useEffect(() => {
        setFeature(null);
    }, [selectedTask?.type]);

    const changeFeature = (newValue: FeatureValue) => setFeature(newValue);

    return { features, feature, changeFeature };
};

export { useFeatureControl };
