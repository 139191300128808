import { SetStateAction } from "react";
import { TFunction } from "i18next";
import { AxiosResponse } from "axios";
import qs from "query-string";
import moment, { Moment } from "moment";

import {
    AUTHENTICATION_TYPES,
    CARRIER,
    COMPANY_FILTER,
    COMPANY_LEVELS,
    COMPANY_TYPES,
    DownloadFileParameters,
    ENDPOINTS,
    ENVIRONMENTS,
    FILE_TYPES,
    Filter,
    FILTER_NAMES,
    FilterDropdownOptions,
    FILTERS_WITH_QUOTES,
    FIND_TOOL_FILTER,
    FIRMWARE_FILE_TYPES,
    ID_TYPE,
    INVALID,
    LANGUAGES,
    NOT_ASSIGNED,
    NOT_FILTER_NAMES,
    ORDERS,
    QueryParamName,
    QueryParams,
    Resource,
    RETRIABLE_TASK_TYPES,
    ROOT_COMPANY,
    SPEC_ID_NOT_ASSIGNED_VALUE,
    TABLE_NAMES,
    TASK_STATUSES,
    TASK_TYPES,
    TELTONIKA_FILES_COMPANY,
    TRANSLATABLE_FILTER_NAMES,
    Vehicle
} from "../";

import { Task } from "../../components/Actions/dialogs/Devices/CreateTask/types";
import { alphabeticalSort } from "../../components/Filters/constants";
import { FileType } from "../../components/Actions/dialogs/Files/types";

export const noop = () => {
    // This is intentional
};

export const getValidLanguage = (lang: any, defaultLang: LANGUAGES) => {
    const isValid = Object.values(LANGUAGES).includes(lang);

    return isValid ? (lang as LANGUAGES) : defaultLang;
};

export const isValidParamName = (paramName: string) => {
    const allNames = Object.values({ ...FILTER_NAMES, ...NOT_FILTER_NAMES });
    return allNames.includes(paramName as any);
};

export const isRootCompanyOrCompanyIdFilter = (
    paramName: FILTER_NAMES | NOT_FILTER_NAMES
) =>
    paramName === FILTER_NAMES.RootCompany ||
    paramName === FILTER_NAMES.CompanyId;

export const isFilter = (paramName: string) =>
    Object.values(FILTER_NAMES).includes(paramName as any);

export const isFilterOrSearch = (paramName: string) =>
    isFilter(paramName) || paramName === NOT_FILTER_NAMES.Query;

export const isTranslatableFilter = (filterName: string) =>
    Object.values(TRANSLATABLE_FILTER_NAMES).includes(filterName as any);

export const isFirmwareFile = (fileType: string) =>
    Object.values(FIRMWARE_FILE_TYPES).includes(fileType as any);

export const isCertificateFile = (fileType: string) =>
    fileType === FILE_TYPES.Certificate;

export const isDeviceTreeFile = (fileType: string) =>
    fileType === FILE_TYPES.Dtb;

export const isFailedOrCompletedWithErrorsTask = (statusId: TASK_STATUSES) =>
    statusId === TASK_STATUSES.Failed ||
    statusId === TASK_STATUSES.CompletedWithErrors;

export const isRetriableTaskType = (taskType: TASK_TYPES) =>
    Object.keys(RETRIABLE_TASK_TYPES).includes(taskType);

export const isPendingOrRunningTask = (statusId: TASK_STATUSES) =>
    statusId === TASK_STATUSES.Pending || statusId === TASK_STATUSES.Running;

export const isRefreshableTaskType = (statusId: TASK_STATUSES) =>
    statusId === TASK_STATUSES.Pending ||
    statusId === TASK_STATUSES.Running ||
    statusId === TASK_STATUSES.Failed ||
    statusId === TASK_STATUSES.CompletedWithErrors;

export const filteredExistingItems = (
    options: Filter[],
    filterValue: string | string[] | undefined | null
) =>
    options.filter(option => {
        const isArray = Array.isArray(filterValue);
        const id = String(option.id);

        return isArray ? filterValue.includes(id) : id === String(filterValue);
    });

export const getRootCompanyValue = (
    allOptions: FilterDropdownOptions,
    rootCompanyId: string
) => {
    const rootCompanies = allOptions[FILTER_NAMES.RootCompany] || [];

    return filteredExistingItems(rootCompanies, rootCompanyId)[0];
};

export const getFilteredQueryParams = (
    params: QueryParams,
    includeSearch?: boolean
) => {
    const allParams = { ...params };
    const allParamNames = Object.keys(allParams) as QueryParamName[];

    allParamNames.forEach(paramName => {
        const mustBeDeleted = includeSearch
            ? !isFilterOrSearch(paramName)
            : !isFilter(paramName);

        if (mustBeDeleted) {
            delete allParams[paramName];
        }
    });

    return allParams;
};

export const getStringifiedParams = (params: QueryParams) => {
    const copyParams = { ...params };
    delete copyParams[NOT_FILTER_NAMES.Selected];

    return qs.stringify(copyParams, {
        arrayFormat: "comma"
    });
};

export const getIds = (rows: any, idType: ID_TYPE) =>
    rows.map((row: any) => row[idType]);

export const handleFilterOptionsStructure = (
    options: Filter[],
    filterName: FILTER_NAMES,
    tableName: TABLE_NAMES,
    taskTypes: Task[],
    t: TFunction<"translation", undefined>
) => {
    const isTypeFilterName = filterName === FILTER_NAMES.Type;

    const nameForSorting = isTypeFilterName
        ? `${tableName.slice(0, -1)}Type`
        : filterName;

    const sortedOptions = sortAndReturnFilterDropdown(
        options,
        nameForSorting,
        t,
        taskTypes
    );

    moveCarrierToBottom(sortedOptions);
    moveInvalidToBottom(sortedOptions);
    addNotAssigned(sortedOptions, filterName);

    return sortedOptions;
};

const sortAndReturnFilterDropdown = (
    options: Filter[],
    filterName: string,
    t: TFunction<"translation", undefined>,
    taskTypes?: Task[]
) => {
    const isTaskTypeFilter =
        filterName === FILTER_NAMES.BatchTaskType ||
        filterName === TRANSLATABLE_FILTER_NAMES.TaskType;

    isTaskTypeFilter && taskTypes && sortTaskTypes(options, taskTypes);

    const shouldBeSortedAlphabetically = alphabeticalSort.includes(
        filterName as FILTER_NAMES | TRANSLATABLE_FILTER_NAMES
    );

    return shouldBeSortedAlphabetically
        ? sortAlphabetically(filterName, options, t)
        : options;
};

const sortTaskTypes = (options: Filter[], taskTypes: Task[]) => {
    const taskOrderMap = taskTypes.reduce<Record<string, number>>(
        (acc, task, index) => {
            acc[task.type] = index;
            return acc;
        },
        {}
    );

    const sortedTaskTypes = () => {
        return options.sort((a, b) => {
            const indexA = taskOrderMap[a.id] ?? Infinity; // Use Infinity for missing items
            const indexB = taskOrderMap[b.id] ?? Infinity;

            return indexA - indexB;
        });
    };

    return sortedTaskTypes();
};

export const sortAlphabetically = (
    filterName: string,
    options: Filter[],
    t: TFunction<"translation", undefined>
) => {
    const isTranslatable = isTranslatableFilter(filterName);

    if (isTranslatable) {
        const optionsWithTranslations = options.map(option => {
            const shouldHaveTranslatedName =
                option.id !== null && option.id !== "Invalid";

            return {
                ...option,
                ...(isTranslatable
                    ? {
                          translatedName: shouldHaveTranslatedName
                              ? t(`General##${option.name}`)
                              : option.name
                      }
                    : {})
            };
        });

        const sortedArray = sortAndReturnArray(
            optionsWithTranslations,
            "translatedName"
        );

        return sortedArray.map(({ id, name }) => ({ id, name }));
    }

    return sortAndReturnArray(options, "name");
};

export const moveCarrierToBottom = (options: (Filter | FileType)[]) => {
    const carrierArray: (Filter | FileType)[] = [];

    for (let i = options.length - 1; i >= 0; i--) {
        const option = options[i];

        const id = "value" in option ? option.value : option.id;

        const isCarrierFileType =
            typeof id === "string" && id.includes(CARRIER);

        if (isCarrierFileType) {
            const actualValue = options.splice(i, 1);

            carrierArray.unshift(...actualValue);
        }
    }

    options.push(...carrierArray);
};

const moveInvalidToBottom = (options: Filter[]) => {
    options.forEach((field: Filter, i: number) => {
        if (field.id === INVALID) {
            const invalidValue = options.splice(i, 1);
            options.push(invalidValue[0]);
        }
    });
};

const addNotAssigned = (options: Filter[], filterName: FILTER_NAMES) => {
    const isSpecId = isSpecIdFilter(filterName);

    if (isSpecId) {
        const shouldHaveNotAssigned = options.some(
            ({ id }) => id === null || id === 0 || id === 1
        );

        for (let i = options.length - 1; i >= 0; i--) {
            const id = options[i].id;

            if (id === null || id === 0 || id === 1) {
                options.splice(i, 1);
            }
        }

        shouldHaveNotAssigned &&
            options.unshift({
                id: SPEC_ID_NOT_ASSIGNED_VALUE,
                name: NOT_ASSIGNED
            });
    } else {
        options.forEach((field: Filter, i: number) => {
            if (field.id === null) {
                options.splice(i, 1);

                options.unshift({
                    id: "null",
                    name: NOT_ASSIGNED
                });
            }
        });
    }
};

export const parseResource = (resource: Resource, data: any): string | null => {
    const isNestedResource = Array.isArray(resource);

    if (isNestedResource) {
        return resource.reduce((object, objectKey) => {
            return object !== null ? object[objectKey] : null;
        }, data);
    } else {
        return data[resource];
    }
};

export const generateColor = (
    inverted: boolean | undefined,
    firstcolor: string,
    secondColor: string
) => (inverted ? firstcolor : secondColor);

export const isGlobalFile = (companyId: number) =>
    companyId === TELTONIKA_FILES_COMPANY;

export const checkIfDownloadIsAllowed = (data: any) =>
    !isGlobalFile(data.company_id) &&
    !isFirmwareFile(data.type) &&
    !isCertificateFile(data.type) &&
    !isDeviceTreeFile(data.type);

export const downloadFile = async (parameters: DownloadFileParameters) => {
    const {
        id,
        getData,
        handleResponse,
        customEndpoint,
        setLoading,
        removeLoading
    } = parameters;

    try {
        setLoading?.();

        const endpoint = customEndpoint || `${ENDPOINTS.Files}/download/${id}`;
        const response = await getData(endpoint, { responseType: "blob" });

        const contentDisposition =
            response.headers["content-disposition"] || "";

        const match = contentDisposition.match(
            /filename\*?=(?:UTF-8'')?"?([^";]*)"?/
        );

        const fileName = match
            ? decodeURIComponent(match[1])
            : "downloaded_file";

        const blob = response.data;
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    } catch (error) {
        handleResponse(error);
    }

    removeLoading?.();
};

export const isAnalyticsCookieAccepted = (cookieConsent: any) =>
    cookieConsent.analytics === "true";

export const getEnvironment = () => {
    const env = process.env.REACT_APP_ENVIRONMENT;

    return {
        env,
        isProductionEnv: env === ENVIRONMENTS.Production,
        isTestEnv: env === ENVIRONMENTS.Staging,
        isDevelopmentEnv:
            env === ENVIRONMENTS.Dev ||
            env === ENVIRONMENTS.Development ||
            env === ENVIRONMENTS.DevelopmentLocalApi,
        isNotLocalEnv:
            env !== ENVIRONMENTS.Development &&
            env !== ENVIRONMENTS.DevelopmentLocalApi
    };
};

export const getFilteredCompaniesIds = (companyFilteredValues: Filter[]) => {
    return companyFilteredValues.map(company => company.id);
};

export const getCompanyFilterQueryParamsFromBrowser = (
    filterName: FILTER_NAMES.RootCompany | FILTER_NAMES.CompanyId
) => {
    try {
        const localStorageName =
            filterName === FILTER_NAMES.RootCompany
                ? ROOT_COMPANY
                : COMPANY_FILTER;

        const value: string | string[] = JSON.parse(
            localStorage.getItem(localStorageName) || ""
        );

        return value.length > 0 ? { [filterName]: value } : {};
    } catch (error) {
        return {};
    }
};

export const getQueryParamsFromBrowser = (resource: TABLE_NAMES) => {
    try {
        return (
            JSON.parse(sessionStorage.getItem(`${resource}Query`) || "") || {}
        );
    } catch (error) {
        return {};
    }
};

export const getFirstLetterUppercase = (value: string) => {
    if (value.length) {
        return value[0].toUpperCase() + value.slice(1);
    }

    return value;
};

export const removeFindToolFilterFromStorage = () =>
    localStorage.removeItem(FIND_TOOL_FILTER);

export const isValidNumberValue = (value: string) => {
    const regexNumber = /^[0-9\b]+$/;

    return value === "" || regexNumber.test(value);
};

export const getFilterParamsWithoutRoot = (filterParams: QueryParams) => {
    const copiedParams = { ...filterParams };

    delete copiedParams[FILTER_NAMES.RootCompany];

    return copiedParams;
};

export const generateArrayOfNumbers = (count: number) =>
    Array.from({ length: count }, (_, i) => i + 1);

export const isClientTypeCompany = (type: any) => type === COMPANY_TYPES.Client;

export const isInternalTypeCompany = (type: any) =>
    type === COMPANY_TYPES.Internal;

export const isAllowedToEditCompany = (
    isSystemUser: boolean,
    companyType: COMPANY_TYPES | undefined
) => {
    if (isInternalTypeCompany(companyType)) {
        return isSystemUser;
    }

    return true;
};

export const isInternalOrPremiumLevel = (
    companyLevel: COMPANY_LEVELS | undefined
) =>
    companyLevel === COMPANY_LEVELS.Internal ||
    companyLevel === COMPANY_LEVELS.Premium;

export const isNumericValue = (value: string) => {
    const regexValue = /^[+-]?(\d+(\.\d*)?|\.\d+)([eE][+-]?\d+)?$/;

    return regexValue.test(value);
};

export const isNumericIntegerValue = (value: string) => {
    const regexValue = /^\d+$/;

    return regexValue.test(value);
};

export const disableDates = (
    momentDate: Moment,
    durationAmount: moment.DurationInputArg1,
    durationUnit: moment.unitOfTime.DurationConstructor
) => {
    const today = moment();
    const maxAllowedDate = today.clone().add(durationAmount, durationUnit);

    return (
        moment(momentDate).isSame(moment(), "day") ||
        moment(momentDate).isAfter(maxAllowedDate, "day")
    );
};

export const isEnglish = (language: LANGUAGES) =>
    language === LANGUAGES.English;

export const isSpecIdFilter = (filterName: FILTER_NAMES) =>
    filterName === FILTER_NAMES.SpecId;

export const getFiltersWithQuotes = () => Object.values(FILTERS_WITH_QUOTES);

const specIdHasAllNotAssignedParts = (valueArray: string[]) =>
    valueArray.includes("null") &&
    valueArray.includes("0") &&
    valueArray.includes("1");

const hasNoSpecIdNotAssigned = (value: string) =>
    value !== "null" && value !== "0" && value !== "1";

export const transformIdArrayWithSpecIdNotAssigned = (
    filterName: FILTER_NAMES,
    idArray: string[]
) => {
    const isSpecId = isSpecIdFilter(filterName);

    if (isSpecId) {
        const hasNotAssigned = specIdHasAllNotAssignedParts(idArray);

        return hasNotAssigned
            ? [
                  ...idArray.filter(hasNoSpecIdNotAssigned),
                  SPEC_ID_NOT_ASSIGNED_VALUE
              ]
            : idArray;
    }

    return idArray;
};

export const updateParamsWithCorrectSpecIdValue = (params: QueryParams) => {
    const updatedQueryParams = { ...params };
    const specIdFilterValue = updatedQueryParams[FILTER_NAMES.SpecId];

    if (specIdFilterValue) {
        const isArray = Array.isArray(specIdFilterValue);
        const valueArray = isArray ? specIdFilterValue : [specIdFilterValue];

        // Normalize zero-like values (e.g., "00", "000") to "0"
        const normalizedValueArray = valueArray.map(value => {
            if (/^0+$/.test(value)) {
                return "0";
            }

            return value;
        });

        const hasNotAssignedPart =
            normalizedValueArray.includes("null") ||
            normalizedValueArray.includes("0") ||
            normalizedValueArray.includes("1");

        if (hasNotAssignedPart) {
            const hasAllNotAssignedAllParts =
                specIdHasAllNotAssignedParts(normalizedValueArray);

            if (!hasAllNotAssignedAllParts) {
                updatedQueryParams[FILTER_NAMES.SpecId] =
                    normalizedValueArray.filter(hasNoSpecIdNotAssigned);
            }
        }
    }

    return updatedQueryParams;
};

const getVehicle = (data: Vehicle) => {
    const { end_year, make, model, start_year } = data;

    const makeCapitalized = `${make.charAt(0).toUpperCase()}${make.slice(1)}`;

    const yearEnd = end_year ? `-${end_year}` : "+";
    const year = `(${start_year}${yearEnd})`;

    return `${makeCapitalized} ${model} ${year}`;
};

export const renderCanOemFileVehicle = (
    vehicle: Vehicle | null,
    vehicleId: number | null,
    t: TFunction<"translation", undefined>
) => {
    if (vehicle) {
        return getVehicle(vehicle);
    }

    if (vehicleId !== null) {
        return `${t("General##unknown")} (${t("General##id")}: ${vehicleId})`;
    }

    return null;
};

export const isAuthenticatorType = (type: AUTHENTICATION_TYPES | null) =>
    type === AUTHENTICATION_TYPES.Authenticator;

export const sortAndReturnArray = <T>(
    data: T[],
    property?: keyof T,
    order: ORDERS = ORDERS.Ascending
): T[] => {
    const applyOrder = (comparison: number) =>
        order === ORDERS.Ascending ? comparison : -comparison;

    return [...data].sort((a, b) => {
        const valueA = property ? a[property] : a;
        const valueB = property ? b[property] : b;

        let comparison = 0;

        if (!valueA && !valueB) return 0;
        if (!valueA) return 1;
        if (!valueB) return -1;

        if (typeof valueA === "string" && typeof valueB === "string") {
            comparison = valueA.localeCompare(valueB);
            return applyOrder(comparison);
        }

        if (typeof valueA === "number" && typeof valueB === "number") {
            comparison = valueA - valueB;
            return applyOrder(comparison);
        }

        return 0;
    });
};
export const sendCodeToEmail = async (
    setLoading: (value: SetStateAction<boolean>) => void,
    successCallback: () => void,
    postData: (
        resource: string,
        payload?: any
    ) => Promise<AxiosResponse<any, any>>,
    handleResponse: (res: any) => void
) => {
    try {
        setLoading(true);
        await postData(ENDPOINTS.TfaSendEmail);
        successCallback();
    } catch (error) {
        handleResponse(error);
    }

    setLoading(false);
};

export const is2FaMandatory = (isSupportUser: boolean, createdAt: string) => {
    if (!isSupportUser) {
        const REFERENCE_DATE = "2025-01-07 00:00";
        return moment(createdAt).isSameOrAfter(moment(REFERENCE_DATE));
    }

    return true;
};

export const isLithuanianLanguage = (language: LANGUAGES) =>
    language === LANGUAGES.Lithuanian;
