/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import CompatibilityContent from "./CompatibilityContent";
import SecondSelectContent from "./SecondSelectContent";
import ScheduleTask from "./ScheduleTask";
import {
    useCanAdapters,
    useCanVehicles,
    useCreateTaskContent,
    useFeatureControl,
    useGeneralDropdown,
    useInternalLog,
    useScheduleTask
} from "./hooks";
import { CreateTaskContentProps } from "./types";

import Autocomplete from "../../../../../DesignComponents/Autocomplete";
import {
    TOOLTIP_PLACEMENT,
    TooltipLarge
} from "../../../../../DesignComponents/Tooltips";
import Checkbox from "../../../../../DesignComponents/Checkbox";

import { DROPDOWN_SIZES } from "../../../../../../shared";

const CreateTaskContent = ({
    isLoading,
    isFinalStep,
    activeSource,
    compatibilityData,
    hasCreateForIncompatible,
    setPayload,
    handleCreateForIncompatible
}: CreateTaskContentProps) => {
    const { t } = useTranslation();

    const {
        isCancelTasksChecked,
        isRestricted,
        isScheduled,
        selectedTask,
        visibleTasks,
        handleChangeValue,
        handleScheduledTime,
        handleSecondSelectData,
        toggleScheduled,
        toggleTaskCancellation
    } = useCreateTaskContent(setPayload);

    const internalLogProps = useInternalLog();
    const canAdapterProps = useCanAdapters(selectedTask);
    const canVehiclesProps = useCanVehicles(selectedTask);
    const featureControlProps = useFeatureControl(selectedTask);
    const generalDropdownProps = useGeneralDropdown(selectedTask);

    const {
        isInverted,
        value,
        fromDate,
        toDate,
        toggleInvertion,
        handleChange
    } = useScheduleTask(handleScheduledTime);

    return isFinalStep && compatibilityData ? (
        <CompatibilityContent
            data={compatibilityData}
            activeSource={activeSource}
            secondSelectName={selectedTask?.secondSelect}
            differentData={{
                internalLogProps,
                canAdapterProps,
                canVehiclesProps,
                featureControlProps,
                generalDropdownProps
            }}
            isCancelTasksChecked={isCancelTasksChecked}
            scheduleTime={isScheduled ? { fromDate, toDate } : null}
            hasCreateForIncompatible={hasCreateForIncompatible}
            isLoading={isLoading}
            handleCreateForIncompatible={handleCreateForIncompatible}
        />
    ) : (
        <>
            <Autocomplete
                data-testid="task-type-dropdown"
                fullWidth
                isRequired
                size={DROPDOWN_SIZES.Medium}
                labelLeft={t("General##task type")}
                textFieldParams={{
                    placeholder: t("Dialog##select")
                }}
                options={visibleTasks}
                groupBy={option => t(`Dialog##${option.group}`)}
                getOptionLabel={option => t(`General##${option.type}`)}
                isOptionEqualToValue={(option, value) =>
                    option.type === value.type
                }
                value={selectedTask}
                readOnly={isLoading}
                onChange={(_, taskType) => handleChangeValue(taskType)}
                customStyle={{
                    marginTop: "16px"
                }}
            />

            {selectedTask?.secondSelect && (
                <SecondSelectContent
                    secondSelectName={selectedTask.secondSelect}
                    type={selectedTask.type}
                    isLoading={isLoading}
                    internalLogProps={internalLogProps}
                    canAdapterProps={canAdapterProps}
                    canVehiclesProps={canVehiclesProps}
                    featureControlProps={featureControlProps}
                    generalDropdownProps={generalDropdownProps}
                    changePayload={handleSecondSelectData}
                />
            )}

            <div
                css={css({
                    marginTop: "16px"
                })}
            >
                <Checkbox
                    data-testid="cancel-pending-tasks-checkbox"
                    label={t("Dialog##cancel pending tasks")}
                    onChange={toggleTaskCancellation}
                    checked={isCancelTasksChecked}
                    disabled={isLoading}
                />
            </div>

            <div
                css={css({
                    display: "flex",
                    margin: "16px 0"
                })}
            >
                <TooltipLarge
                    placement={TOOLTIP_PLACEMENT.TopStart}
                    title={
                        isRestricted ? t(`Restrictions##general message`) : ""
                    }
                    customMargin="0 0 6px 0"
                >
                    <span css={css({ display: "inline-flex" })}>
                        <Checkbox
                            data-testid="schedule-time-checkbox"
                            label={t("Dialog##schedule time")}
                            onChange={toggleScheduled}
                            checked={isScheduled}
                            disabled={isRestricted || isLoading}
                        />
                    </span>
                </TooltipLarge>
            </div>

            {isScheduled && (
                <ScheduleTask
                    isInverted={isInverted}
                    isLoading={isLoading}
                    fromDate={fromDate}
                    toDate={toDate}
                    value={value}
                    handleChange={handleChange}
                    toggleInvertion={toggleInvertion}
                />
            )}
        </>
    );
};

export default CreateTaskContent;
