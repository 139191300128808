import { useEffect, useState } from "react";

import {
    Make,
    Model,
    SortedVehicles,
    Vehicle,
    Vehicles
} from "../SecondSelectContent/CanVehicles/types";
import { SelectedTask } from "../types";

import {
    API_IS_BEING_CANCELLED,
    ENDPOINTS,
    getFirstLetterUppercase,
    sortAndReturnArray,
    TASK_TYPES,
    useApi,
    useIsMounted
} from "../../../../../../../shared";

const useCanVehicles = (selectedTask: SelectedTask) => {
    const { getData, handleResponse, cancelSource, isCanceled } = useApi();
    const isMounted = useIsMounted();

    const [isLoading, setLoading] = useState(false);
    const [vehicles, setVehicles] = useState<Vehicles>(null);
    const [makes, setMakes] = useState<string[]>([]);
    const [make, setMake] = useState<Make>(null);
    const [model, setModel] = useState<Model>(null);

    useEffect(() => {
        setMake(null);
        setModel(null);

        const getVehicles = async () => {
            try {
                setLoading(true);

                const { data } = await getData(
                    `${ENDPOINTS.CanAdapters}/vehicles`
                );

                if (isMounted) {
                    const sortVehicles = () => {
                        const allVehicles: Vehicle[] = data.vehicles;

                        const sortedVehicles =
                            allVehicles.reduce<SortedVehicles>(
                                (
                                    previous,
                                    {
                                        make,
                                        model,
                                        vehicle_id,
                                        year_start,
                                        year_end
                                    }
                                ) => {
                                    const currentValue = {
                                        vehicle_id,
                                        model: getFirstLetterUppercase(model),
                                        year_start,
                                        year_end
                                    };

                                    const existingValue =
                                        previous[
                                            getFirstLetterUppercase(make)
                                        ] || [];

                                    return {
                                        ...previous,
                                        ...(make
                                            ? {
                                                  [getFirstLetterUppercase(
                                                      make
                                                  )]: [
                                                      ...existingValue,
                                                      currentValue
                                                  ]
                                              }
                                            : {})
                                    };
                                },
                                {}
                            );

                        setVehicles(sortedVehicles);

                        setMakes(
                            sortAndReturnArray(Object.keys(sortedVehicles))
                        );
                    };

                    sortVehicles();
                }
            } catch (error) {
                if (isMounted && !isCanceled(error)) {
                    handleResponse(error);
                }
            }

            setLoading(false);
        };

        const isUploadCanOemFileTask =
            selectedTask?.type === TASK_TYPES.TxCanConfiguration;

        isUploadCanOemFileTask && getVehicles();

        return () => {
            cancelSource.cancel(API_IS_BEING_CANCELLED);
        };

        // eslint-disable-next-line
    }, [selectedTask?.type]);

    const changeMake = (makeName: string) => {
        setMake(makeName);
        setModel(null);
    };

    const changeModel = (vehicleInfo: Model) => setModel(vehicleInfo);

    return {
        additionalData: {
            isVehiclesLoading: isLoading,
            vehicles,
            makes,
            make,
            model
        },
        changeMake,
        changeModel
    };
};

export { useCanVehicles };
