/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import { PLATFORMS, WarningMessageProps } from "./types";

import BannerMessage, {
    BANNER_MESSAGE_STATUSES
} from "../../../../../../../DesignComponents/BannerMessage";

const WarningMessage = ({ compatiblePlatforms }: WarningMessageProps) => {
    const { t } = useTranslation();

    const getPlatformVariable = () => {
        const hasFt = compatiblePlatforms.includes(PLATFORMS.Ft);
        const hasFmx = compatiblePlatforms.includes(PLATFORMS.Fmx);

        const isOnlyForFtPlatform = hasFt && !hasFmx;
        const isOnlyForFmxPlatform = hasFmx && !hasFt;

        if (isOnlyForFtPlatform) {
            return t("Dialog##platform compatibility##for ft platform");
        }

        if (isOnlyForFmxPlatform) {
            return t("Dialog##platform compatibility##for fmx platform");
        }

        return t("Dialog##platform compatibility##for both platforms");
    };

    const titleFirstPart = t(
        "Dialog##platform compatibility##warning first part",
        { platform: getPlatformVariable() }
    );

    const titleSecondPart = t(
        "Dialog##platform compatibility##warning second part"
    );

    const title = `${titleFirstPart} ${titleSecondPart}`;

    return (
        <BannerMessage
            status={BANNER_MESSAGE_STATUSES.Info}
            title={title}
            css={css({
                marginBottom: "16px"
            })}
        />
    );
};

export default WarningMessage;
export { PLATFORMS };
