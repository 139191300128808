import { useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";

import useBootstring from "../hooks/useBootstring";
import CopyButton from "../../../DesignComponents/CopyButton";
import { BUTTON_SIZES } from "../../../DesignComponents/Button";

import AuthContext from "../../../../context/auth/authContext";
import TableContext from "../../../../context/table/tableContext";

const CanAdapter = () => {
    const { isAdminUser } = useContext(AuthContext);

    const { detailsData } = useContext(TableContext);

    const {
        can_adapter: { bootstring, type }
    } = detailsData;

    const { t } = useTranslation();
    const { parsedBootstringData } = useBootstring();

    const programNumber = parsedBootstringData(bootstring, 1);
    const softwareDate = parsedBootstringData(bootstring, 2);

    return (
        <Fragment>
            <div>{t("Table##can_adapter")}</div>

            <div>
                {/* CAN bootstring */}
                {isAdminUser && (
                    <Fragment>
                        <span>{t("Table##can bootstring")}:</span>

                        <span>
                            {bootstring}{" "}
                            <CopyButton
                                size={BUTTON_SIZES.Tiny}
                                copiedValue={bootstring}
                            />
                        </span>
                    </Fragment>
                )}
                {/* */}

                {/* Type */}
                <Fragment>
                    <span>{t("Table##type")}:</span>

                    <span>
                        {type}{" "}
                        <CopyButton
                            size={BUTTON_SIZES.Tiny}
                            copiedValue={type}
                        />
                    </span>
                </Fragment>
                {/*  */}

                {/* Program number */}
                {programNumber && (
                    <Fragment>
                        <span>{t("Table##program number")}:</span>

                        <span>
                            {programNumber}{" "}
                            <CopyButton
                                size={BUTTON_SIZES.Tiny}
                                copiedValue={programNumber}
                            />
                        </span>
                    </Fragment>
                )}
                {/* */}

                {/* Software date */}
                {softwareDate && (
                    <Fragment>
                        <span>{t("Table##software date")}:</span>

                        <span>
                            {softwareDate}{" "}
                            <CopyButton
                                size={BUTTON_SIZES.Tiny}
                                copiedValue={softwareDate}
                            />
                        </span>
                    </Fragment>
                )}
                {/* */}
            </div>
        </Fragment>
    );
};

export default CanAdapter;
