/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Trans } from "react-i18next";
import { TFunction } from "i18next";

import { CanAdapterType } from "../SecondSelectContent/CanAdapters/types";
import { VehicleInfo } from "../SecondSelectContent/CanVehicles/types";
import { AdditionalData } from "../SecondSelectContent/GeneralDropdown/types";
import {
    CUSTOM_SECOND_SELECT,
    SecondSelectType
} from "../SecondSelectContent/types";

import BannerMessage, {
    BANNER_MESSAGE_STATUSES
} from "../../../../../../DesignComponents/BannerMessage";
import TextLink, {
    TEXT_LINK_SIZES
} from "../../../../../../DesignComponents/TextLink";

const renderCanAdapterType = (adapter: CanAdapterType) =>
    `${adapter.type} (${adapter.seo_name})`;

const renderVehicleModel = (option: VehicleInfo) => {
    const { model, year_start, year_end } = option;

    const yearEnd = year_end ? `-${year_end}` : "+";
    const year = `(${year_start}${yearEnd})`;

    return `${model} ${year}`;
};

const renderTxFirmwareBanner = (data: AdditionalData) => {
    const { fileAttributes } = data;

    if (fileAttributes) {
        try {
            const parsedData = JSON.parse(fileAttributes);
            const firmwareVersion = parsedData?.version;

            if (firmwareVersion) {
                const majorVersion = Number(firmwareVersion.split(".")[0]);
                return majorVersion >= 4 && majorVersion < 9;
            }
        } catch (error) {
            return false;
        }
    }

    return false;
};

const renderAdditionalBanner = (
    secondSelectName: SecondSelectType,
    data: AdditionalData,
    t: TFunction<"translation", undefined>
) => {
    const isUploadTlsCertificate =
        secondSelectName === CUSTOM_SECOND_SELECT.Certificate;

    if (isUploadTlsCertificate) {
        return (
            <BannerMessage
                status={BANNER_MESSAGE_STATUSES.Warning}
                title={t("Dialog##tls certificate transfer info")}
                css={css({
                    marginTop: "16px"
                })}
            />
        );
    }

    if (renderTxFirmwareBanner(data)) {
        return (
            <BannerMessage
                status={BANNER_MESSAGE_STATUSES.Warning}
                title={
                    <Trans
                        i18nKey="Dialog##upload firmware info"
                        components={{
                            1: (
                                <TextLink
                                    href="https://wiki.teltonika-gps.com/view/FMB_firmware_errata_04.00.00"
                                    size={TEXT_LINK_SIZES.Small}
                                    target="_blank"
                                >
                                    Teltonika GPS Wiki
                                </TextLink>
                            )
                        }}
                    />
                }
                css={css({
                    marginTop: "16px"
                })}
            />
        );
    }

    return null;
};

export { renderCanAdapterType, renderVehicleModel, renderAdditionalBanner };
