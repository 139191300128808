/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import WarningMessage, { PLATFORMS } from "./WarningMessage";
import Cards from "./Cards";
import CountText from "./CountText";
import CheckboxOption from "./CheckboxOption";
import { CompatibilityContentProps } from "./types";

import { useFormattedNumber } from "../../../../../../../shared";

const CompatibilityContent = ({
    data,
    activeSource,
    secondSelectName,
    differentData,
    isCancelTasksChecked,
    scheduleTime,
    hasCreateForIncompatible,
    isLoading,
    handleCreateForIncompatible
}: CompatibilityContentProps) => {
    const { renderFormattedNumber } = useFormattedNumber();

    const {
        compatible_device_platforms,
        compatible_platform_device_count,
        device_count,
        task_type
    } = data;

    const compatiblePlatforms = compatible_device_platforms as PLATFORMS[];

    const compatibleCount = renderFormattedNumber(
        compatible_platform_device_count
    );
    const totalCount = renderFormattedNumber(device_count);

    const hasCheckboxOption =
        compatible_platform_device_count !== 0 &&
        compatible_platform_device_count !== device_count;

    return (
        <div
            css={css({
                margin: "8px 0 4px 0"
            })}
        >
            <WarningMessage compatiblePlatforms={compatiblePlatforms} />

            <Cards
                compatiblePlatforms={compatiblePlatforms}
                compatibleCount={compatibleCount}
                totalCount={totalCount}
                taskType={task_type}
                activeSource={activeSource}
                secondSelectName={secondSelectName}
                differentData={differentData}
                isCancelTasksChecked={isCancelTasksChecked}
                scheduleTime={scheduleTime}
            />

            <CountText
                compatibleCount={compatibleCount}
                totalCount={totalCount}
                hasCreateForIncompatible={hasCreateForIncompatible}
            />

            {hasCheckboxOption && (
                <CheckboxOption
                    value={hasCreateForIncompatible}
                    isLoading={isLoading}
                    onChange={handleCreateForIncompatible}
                />
            )}
        </div>
    );
};

export default CompatibilityContent;
